$orange: #f2623f;
$gray: #595959;
$darkGray: #353535;

@mixin margins {
  margin: {
    left: 10%;
    right: 10%;
  }
}

@mixin box {
  width: 38.5vw;
  height: 300px;
  margin: {
    left: auto;
    right: auto;
    bottom: 15px;
  }
}

@mixin overlay {
  position: relative;
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
  .textbox {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin subpageText {
  p {
    font-size: 18px;
    line-height: 1.5;
  }
  a {
    transition: 0.4s ease;
  }
}

@mixin backgroundImage {
  background-position: center;
  background-size: cover;
}

@mixin projectTextMargins {
  margin: {
    left: 10%;
    right: 10%;
  }
}

@mixin projectHeader {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 45vh;
  @include margins;
  padding: 0;
  position: relative;
  margin-bottom: 1%;
  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    margin: 0;
    font-size: 40px;
    text-shadow: 0.5px 0.5px gray;
  }
}

@mixin headerLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin scStyles {
  width: 60%;
  margin: {
    left: auto;
    right: auto;
    top: 40px;
  }
  p {
    @include imageCaption;
  }
  img {
    width: 100%;
  }
}

@mixin imageCaption {
  text-align: center;
  margin-bottom: 25px;
  font-size: 16px;
  color: lighten($gray, 38%);
}

@mixin imageBorder {
  border: 1px solid #e2e2e2;
}

@mixin oneColumnImages {
  @include scStyles;
  img {
    @include imageBorder;
  }
  div {
    margin-bottom: 25;
  }
  p {
    @include imageCaption;
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  color: $gray;
  margin: 0;
  letter-spacing: 0.07rem;
}

a {
  text-decoration: none;
  color: $orange;
}

h3 {
  text-align: center;
  font-size: 32px;
}

h4 {
  font-size: 30px;
  margin: 0;
}

h5 {
  font-size: 18px;
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.small-text {
  text-transform: uppercase;
  font-size: 15px;
}

.italic {
  font-style: italic;
}

.menu-links {
  display: flex;
  width: 25%;
  justify-content: space-between;
  font-size: 18px;
  a {
    transition: 0.5s ease;
    &:hover {
      color: $gray;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: {
    top: 100px;
    left: 10%;
    bottom: 90px;
    right: 10%;
  }
}

.logo {
  height: 120px;
}

@media (max-width: 881px) {
  .header {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }
  .menu-links {
    margin: {
      top: 3%;
      bottom: 7%;
    }
    width: 50%;
  }
}

@media (max-width: 375px) {
  .menu-links {
    width: 60%;
  }
  .logo {
    height: 80px;
  }
}

.work {
  @include margins;
}

.about {
  @include margins;
  @include subpageText;
  p {
    margin: {
      left: 7%;
      top: 0;
    }
  }
}

.about-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 86%;
  margin: {
    left: auto;
    right: auto;
    top: 50px;
  }
  .about-pic {
    width: 16vw;
    text-align: center;
  }
  .about-desc {
    width: 60%;
  }
  img {
    width: 100%;
    border-radius: 50%;
    margin: {
      left: auto;
      right: auto;
    }
  }
}

@media (max-width: 838px) {
  .about {
    p {
      margin-left: 0;
    }
  }
  .about-info {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    width: 100%;
    .about-pic {
      margin-bottom: 1vh;
      width: 45%;
    }
  }
}

.contact {
  @include margins;
  @include subpageText;
  text-align: center;
  a {
    transition: 0.4s ease;
    &:hover {
      color: $gray;
    }
  }
}

.gallery-thumb {
  @include box;
  @include overlay;
  @include backgroundImage;
}

@media (max-width: 627px) {
  .gallery-thumb {
    height: 200px;
  }
}

.lingomd {
  background-image: url('./assets/images/lingomd-thumb-01.jpg');
}

.startup {
  background-image: url('./assets/images/startup-thumb-alt-03.jpg');
}

.koe {
  background-image: url('./assets/images/koe-thumb.jpg');
}

.potter-wiki {
  background-image: url('./assets/images/potter-thumb-03.jpg');
}

.google {
  background-image: url('./assets/images/google-thumb.jpg');
}

.brickell {
  background-image: url('./assets/images/bcc-thumb.jpg');
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.4s ease;
  background-color: $darkGray;
  color: white;
}

.textbox {
  p, h5 {
    margin: 0;
    margin-top: 20px;
  }
}

.project-text {
  @include projectTextMargins;
  @include subpageText;
  width: 48%;
  text-align: center;
  margin: {
    left: auto;
    right: auto;
    top: 6vh;
  }
  a {
    &:hover {
      color: $gray;
    }
  }
}

.koe-mockup {
  margin-top: 40px;
  text-align: center;
  img {
    width: 70%;
    height: auto;
    margin: {
      left: auto;
      right: auto;
    }
  }
}

.koe-logo {
  height: 32vh;
  position: absolute;
  @include headerLogo;
}

.koe-background {
  background-image: url('./assets/images/koe-headertest-01.png');
  @include projectHeader;
}

.google-background {
  background-image: url('./assets/images/google-bg.png');
  @include projectHeader;
}

.google-weblogo {
  height: 35vh;
  @include headerLogo;
}

.lingomd-background {
  background-image: url('./assets/images/lingomd-bg.jpg');
  @include projectHeader;
}

.lingomd-logo {
  height: 10vh;
  @include headerLogo;
}

.lingomd-sc {
  @include scStyles;
  .lingomd-results {
    @include imageBorder;
    margin-top: 20px;
  }
}

.bcc-background {
  background-image: url('./assets/images/BCC-442.png');
  @include projectHeader;
}

.bcc-header {
  @include headerLogo;
  position: absolute;
  img {
    height: 20vh;
  }
}

.bcc-photos {
  @include oneColumnImages;
  img {
    margin-bottom: 20px;
    border: 0;
  }
  .paired-sc {
    margin-top: 0;
    img {
      @include imageBorder;
    }
  }
}

.startup-background {
  background-image: url('./assets/images/contact-bg.jpg');
  @include projectHeader;
}

.startup-header {
  @include headerLogo;
  position: absolute;
  img {
    height: 12vh;
  }
}

.startup-sc {
  @include oneColumnImages;
}

.potter-background {
  background-image: url('./assets/images/hogwarts-bg.jpg');
  @include projectHeader;
}

.potter-header {
  @include headerLogo;
  position: absolute;
  img {
    height: 25vh;
  }
}

.potter-sc {
  @include oneColumnImages;
}

.video-container {
  padding: 56.25% 0 0 0;
  margin: {
    top: 22px;
    bottom: 32px;
  }
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  p {
    margin-top: 20px;
  }
}

.video-msg {
  margin-top: 32px;
}

.paired-sc {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  div {
    width: 49%;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 627px) {
  .lingomd-sc, .startup-sc, .potter-sc {
    width: 85%;
  }
  .paired-sc {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
}

.footer {
  margin-top: 50px;
  text-align: center;
  p {
    margin: 0;
    padding: {
      top: 5vh;
      bottom: 5vh;
    }
  }
}

@media (max-width: 675px){
  .footer {
    margin-top:0;
    p {
      padding-top: 3vh;
    }
  }
}
